package kangaroorewards.appsdk.core.network

import co.touchlab.kermit.Logger
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.http.HttpStatusCode.Companion.Unauthorized
import kangaroorewards.appsdk.core.SdkContext
import kangaroorewards.appsdk.core.domain.ResultMetaData
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.io.IOResult.*
import kangaroorewards.appsdk.core.io.Model
import kangaroorewards.appsdk.core.network.base.*
import kangaroorewards.appsdk.core.network.base.KHttpClient.customBearerClient
import kangaroorewards.appsdk.core.network.base.KHttpClient.defaultClient
import kangaroorewards.appsdk.core.utils.Configuration

abstract class NetworkRequest {

    companion object {
        // TODO("Add network timeout count down, maybe using flows...")
        //  eg after 5 seconds: "please be patient" - 10 seconds: "shouldn't be much longer"...
        private const val networkTimeout = 20_000L
    }

    /**
     * GET request
     *
     * @param fields Optional list of fields to send in the request.
     */
    @Suppress("UNUSED_PARAMETER")
    suspend inline fun <reified T : Model> get(
        queries: List<Query> = emptyList(),
        endpoint: Endpoint,
        vararg fields: Field,
        serverHostId: Int,
        overrideHeaders: Map<String, String>? = null,
    ): T {

        val client = if (overrideHeaders != null && !overrideHeaders["Authorization"].isNullOrEmpty()) customBearerClient else defaultClient

        val result = client.get {
            url("${Configuration.getServerHostUrl(serverHostId)}${endpoint.path}")
            contentType(ContentType.Application.Json)
            headers{
                overrideDefaultHeaders(
                    headers {
                        clear()
                        append("Accept", "application/vnd.kangaroorewards.api.v1+json")
                        append("client_id", SdkContext.clientId ?: "")
                        append("client_secret", SdkContext.clientSecret ?: "")
                        append("X-Application-Key", SdkContext.applicationKey ?: "")
                        append("Accept-Language", SdkContext.tokenStore?.getPreferredLanguage() ?: "")
                    },
                    overrideHeaders
                )
            }
            queries.forEach { parameter(it.name, it.value) }
        }
        if (!result.status.isSuccess()) {
            throw ClientRequestException(response = result, cachedResponseText= result.body());
        }
        
        return result.body()
    }

    /**
     * POST body data
     *
     * @param requestBody Request body for this post request
     */
    protected suspend inline fun <reified T : Model, reified B: Any> post(
        endpoint: Endpoint,
        headers: List<Header> = emptyList(),
        queries: List<Query> = emptyList(),
        fields: List<FormField> = emptyList(),
        requestBody: B? = null,
        serverHostId: Int,
        overrideHeaders: Map<String, String>? = null,
    ): T {
        if (requestBody != null && fields.isNotEmpty()) {
            throw  IllegalArgumentException(
                "A POST request can either have a body or a set of fields, but not both."
            )
        }

        val client = if (overrideHeaders != null && !overrideHeaders["Authorization"].isNullOrEmpty()) customBearerClient else defaultClient

        val result = client.post {
            buildPatchOrPostRequest(
                endpoint, headers, queries, fields, requestBody, serverHostId, overrideHeaders
            )
        }

        if (!result.status.isSuccess()) {
            throw ClientRequestException(response = result, cachedResponseText= result.body());
        }

        return result.body()
    }

    /**
     * POST form data
     *
     * @param fields List of fields to send in the request. If you are required to post your data
     * as a Json object, use post() instead of postForm()
     */
    suspend inline fun <reified T : Model> postForm(endpoint: Endpoint, vararg fields: FormField, overrideHeaders: Map<String, String>? = null,): T {

        val client = if (overrideHeaders != null && !overrideHeaders["Authorization"].isNullOrEmpty()) customBearerClient else defaultClient

        val result = client.submitForm {
            url("${Configuration.getBaseUrl()}${endpoint.path}")
            headers {
                overrideDefaultHeaders(
                    headers {
                        clear()
                        append("Accept", "application/vnd.kangaroorewards.api.v1+json")
                        append("client_id", SdkContext.clientId ?: "")
                        append("client_secret", SdkContext.clientSecret ?: "")
                        append("X-Application-Key", SdkContext.applicationKey ?: "")
                        append("Accept-Language", SdkContext.tokenStore?.getPreferredLanguage() ?: "")
                    },
                    overrideHeaders
                )
            }

            val body = FormDataContent(Parameters.build {
                // TODO Check with back-end team why we need to pass
                //  these fields in body of auth request only
                if (endpoint.path == "oauth/token") {
                    append("client_id", SdkContext.clientId ?: "")
                    append("client_secret", SdkContext.clientSecret ?: "")
                    append("application_key", SdkContext.applicationKey ?: "")
                }
                fields.forEach { field ->
                    field.value?.let {
                        append(
                            name = field.name,
                            value = field.value.toString()
                        )
                    }
                }
            })
            setBody(body)
        }

        if (!result.status.isSuccess()) {
            throw ClientRequestException(response = result, cachedResponseText= result.body());
        }

        print("reading body")
        val body = result.body<T>()
        print("returning body")
        return body
    }

    /**
     * POST form data
     *
     * @param fields List of fields to send in the request. If you are required to post your data
     * as a Json object, use post() instead of postForm()
     */
    suspend inline fun <reified T : Model> submitRefreshTokensForm(endpoint: Endpoint, vararg fields: FormField): T {
        return defaultClient.submitForm(
            url = "${Configuration.getBaseUrl()}${endpoint.path}",
            formParameters = Parameters.build {
                append("client_id", SdkContext.clientId ?: "")
                append("client_secret", SdkContext.clientSecret ?: "")
                append("X-Application-Key", SdkContext.applicationKey ?: "")
                fields.forEach { field ->
                    field.value?.let {
                        append(
                            name = field.name,
                            value = field.value.toString()
                        )
                    }
                }
            }
        ).body()
    }

    /**
     * PATCH body data
     *
     * @param requestBody Request body for this patch request
     */
    protected suspend inline fun <reified T : Model, reified B: Any?> patch(
        endpoint: Endpoint,
        headers: List<Header> = emptyList(),
        queries: List<Query> = emptyList(),
        fields: List<FormField> = emptyList(),
        requestBody: B? = null,
        serverHostId: Int,
        overrideHeaders: Map<String, String>? = null,
    ): T {
        println("inside patch call")
        if (requestBody != null && fields.isNotEmpty()) {
            throw  IllegalArgumentException(
                "A POST request can either have a body or a set of fields, but not both."
            )
        }

        val client = if (overrideHeaders != null && !overrideHeaders["Authorization"].isNullOrEmpty()) customBearerClient else defaultClient

        val result = client.patch {
            buildPatchOrPostRequest(
                endpoint, headers, queries, fields, requestBody, serverHostId, overrideHeaders
            )
        }

        if (!result.status.isSuccess()) {
            throw ClientRequestException(response = result, cachedResponseText= result.body());
        }

        return result.body()
    }

    inline fun <reified B>HttpRequestBuilder.buildPatchOrPostRequest(
        endpoint: Endpoint,
        headers: List<Header> = emptyList(),
        queries: List<Query> = emptyList(),
        fields: List<FormField> = emptyList(),
        requestBody: B? = null,
        serverHostId: Int,
        overrideHeaders: Map<String, String>? = null,
    ) {
        url("${Configuration.getServerHostUrl(serverHostId)}${endpoint.path}")

        headers {
            overrideDefaultHeaders(
                headers {
                    clear()
                    append("Accept", "application/vnd.kangaroorewards.api.v1+json")
                    append("client_id", SdkContext.clientId ?: "")
                    append("client_secret", SdkContext.clientSecret ?: "")
                    append("X-Application-Key", SdkContext.applicationKey ?: "")
                    append("Accept-Language", SdkContext.tokenStore?.getPreferredLanguage() ?: "")
                },
                overrideHeaders
            )
        }
        headers.forEach { header(it.name, it.value) }

        queries.forEach { parameter(it.name, it.value) }

        /* Fields with a null value are dropped from the request entirely */
        fields.filter { it.value != null }.also { validFields ->
            val body = FormDataContent(Parameters.build {
                validFields.forEach { field ->
                    append(
                        name = field.name, value = field.value.toString()
                    )
                }
            })
            setBody(body)
        }

        if (requestBody != null) {
            contentType(ContentType.Application.Json)
            val body = requestBody
            setBody(body)
        }
    }

    /**
     * PATCH form data
     *
     * @param fields List of fields to send in the request. If you are required to patch your data
     * as a Json object, use patch() instead of patchForm()
     */
    internal suspend inline fun <reified T : Model> patchForm(endpoint: Endpoint, vararg fields: FormField, serverHostId: Int, overrideHeaders: Map<String, String>? = null,): T {
        val result = defaultClient.patch {
            url("${Configuration.getServerHostUrl(serverHostId)}${endpoint.path}")
            headers {
                overrideDefaultHeaders(
                    headers {
                        clear()
                        append("Accept", "application/vnd.kangaroorewards.api.v1+json")
                        append("client_id", SdkContext.clientId ?: "")
                        append("client_secret", SdkContext.clientSecret ?: "")
                        append("X-Application-Key", SdkContext.applicationKey ?: "")
                        append("Accept-Language", SdkContext.tokenStore?.getPreferredLanguage() ?: "")
                    },
                    overrideHeaders
                )
            }

            val body = FormDataContent(Parameters.build {
                fields.forEach { field ->
                    field.value?.let {
                        append(
                            name = field.name,
                            value = field.value.toString()
                        )
                    }
                }
            })
            setBody(body)
        }

        if (!result.status.isSuccess()) {
            throw ClientRequestException(response = result, cachedResponseText= result.body());
        }

        return result.body()
    }


    /**
     * PATCH body data
     *
     * @param requestBody Request body for this patch request
     */
    protected suspend inline fun <reified T : Model, reified B: Any> put(
        endpoint: Endpoint,
        headers: List<Header> = emptyList(),
        queries: List<Query> = emptyList(),
        fields: List<FormField> = emptyList(),
        requestBody: B? = null,
        serverHostId: Int,
        overrideHeaders: Map<String, String>? = null,
    ): T {
        if (requestBody != null && fields.isNotEmpty()) {
            throw  IllegalArgumentException(
                "A POST request can either have a body or a set of fields, but not both."
            )
        }

        val client = if (overrideHeaders != null && !overrideHeaders["Authorization"].isNullOrEmpty()) customBearerClient else defaultClient

        val result = client.put {
            url("${Configuration.getServerHostUrl(serverHostId)}${endpoint.path}")

            headers {
                overrideDefaultHeaders(
                    headers {
                        clear()
                        append("Accept", "application/vnd.kangaroorewards.api.v1+json")
                        append("client_id", SdkContext.clientId ?: "")
                        append("client_secret", SdkContext.clientSecret ?: "")
                        append("X-Application-Key", SdkContext.applicationKey ?: "")
                        append("Accept-Language", SdkContext.tokenStore?.getPreferredLanguage() ?: "")
                    },
                    overrideHeaders
                )
            }
            headers.forEach { header(it.name, it.value) }

            contentType(ContentType.Application.Json)

            queries.forEach { parameter(it.name, it.value) }

            /* Fields with a null value are dropped from the request entirely */
            fields.filter { it.value != null }.also { validFields ->
                val body = FormDataContent(Parameters.build {
                    validFields.forEach { field ->
                        append(
                            name = field.name, value = field.value.toString()
                        )
                    }
                })
                setBody(body)
            }

            if (requestBody != null) {
                setBody(requestBody)
            }
        }

        if (!result.status.isSuccess()) {
            throw ClientRequestException(response = result, cachedResponseText= result.body());
        }

        return result.body()
    }


    @Suppress("UNUSED_PARAMETER")
    suspend inline fun <reified T : Model, reified B: Any> delete(endpoint: Endpoint, vararg fields: Field, serverHostId: Int, overrideHeaders: Map<String, String>? = null,): T {

        val client = if (overrideHeaders != null && !overrideHeaders["Authorization"].isNullOrEmpty()) customBearerClient else defaultClient

        val result = client.delete {
            url("${Configuration.getServerHostUrl(serverHostId)}${endpoint.path}")
            contentType(ContentType.Any)
            headers {
                overrideDefaultHeaders(
                    headers {
                        clear()
                        append("Accept", "application/vnd.kangaroorewards.api.v1+json")
                        append("client_id", SdkContext.clientId ?: "")
                        append("client_secret", SdkContext.clientSecret ?: "")
                        append("X-Application-Key", SdkContext.applicationKey ?: "")
                        append("Accept-Language", SdkContext.tokenStore?.getPreferredLanguage() ?: "")
                    },
                    overrideHeaders
                )
            }
        }

        if (!result.status.isSuccess()) {
            throw ClientRequestException(response = result, cachedResponseText= result.body());
        }

        return result.body()
    }

    fun overrideDefaultHeaders(existingHeaders: HeadersBuilder, overrideHeaders:Map<String, String>?): HeadersBuilder {
        if(!overrideHeaders.isNullOrEmpty()){
            return existingHeaders.apply {
                existingHeaders.entries().forEach { (key, value) ->
                    val newValue = overrideHeaders[key] ?: value
                    set(key, newValue.toString())
                }

                overrideHeaders.filterKeys { it -> it !in existingHeaders.entries().map { it.key } }
                    .forEach { (key, value) ->
                        append(key, value)
                    }
            }
        }
        else{
            return existingHeaders;
        }
    }

    /**
     * Base network call that handles all errors. No exceptions should be thrown from this function;
     * any errors and successes should be wrapped into a NetworkResult to be handled by the consumer.
     */
    suspend fun <T : Model> safeNetworkCall(call: suspend () -> T): IOResult<T> {
        return safeNetworkResult(call)
    }

    /**
     * Base network result that will either return a success or an error.
     *
     */
    private suspend fun <T : Model> safeNetworkResult(
        call: suspend () -> T?,
    ): IOResult<T> {
        Logger.d {"safe call start"}
        try {
            //TODO fix withTimeoutOrNull on iOS
//            val result: T? = withTimeoutOrNull(networkTimeout) {
//                call.invoke()
//            }

            val result: T? = call.invoke()

//            if(isDebug){
//                println("safe call ${result}")
//            }
            return if (result != null) {
                Success(
                    data = result
                )
            } else {
                // Network request timed out due to @withTimeoutOrNull and there is null
                TimeoutError(
                    error = ResultMetaData(
                        type = "TimeoutError",
                        code = 408,
                        msg = "network timeout @${networkTimeout} ms"
                    )
                )
            }
            // TODO("abstract Ktor exceptions away")
        } catch (e: ClientRequestException) {
            val errorCode = e.response.status.value
            val errorMessage = e.response.status.description
            return when (e.response.status) {
                /*
                 * Explicitly return certain errors as NetworkResult.Error types.
                 * This allows the consumer to react to certain network errors at a low level.
                 * For example, unauthorized will often warrant signing out the user, resulting
                 * in some sort of UI navigation (normal screen -> user login screen)
                 *
                 * Any error that doesn't obviously lead to consumer actions are returned
                 * as a OtherError
                 */
                Unauthorized -> {
                    println("Result from Kotlin SDK + Ktor: $errorCode + $errorMessage")
                    UnauthorizedError(
                        error = ResultMetaData(
                            type = "UnauthorizedError",
                            code = errorCode,
                            msg = errorMessage
                        )
                    )
                }
                else -> UnknownError(
                    error = ResultMetaData(
                        type = "UnknownError",
                        code = errorCode,
                        msg = errorMessage
                    )
                )
            }
        } catch (e: NoConnectivityException) {
            return ConnectionError(
                /*
                 * TODO("Investigate best option...")
                 * Using 506 error here, but not sure what to return. Will having this error
                 * code be confusing since it is a consumer set timeout, and not a timeout returned
                 * from the server?
                 */
                error = ResultMetaData(
                    type = "ConnectionError",
                    code = 506,
                    msg = e.message
                )
            )
        } catch (e: NoTransformationFoundException) {
            println("safe call $e ${e.cause} ${e.message} ")
            return EmptyResponse()
        }
    }
}